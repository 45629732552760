@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../node_modules/ti-icons/css/themify-icons.css";

@import 'spec/settings/index';
@import 'spec/tools/index';
@import 'spec/index';
//@import 'vendor/index';

@import '../../node_modules/themify-icons-sass/themify-icons/themify-icons';
@import '../../node_modules/smartwizard/dist/css/smart_wizard.min.css';
//@import "../../node_modules/datatables.net-bs/css/dataTables.bootstrap.min.css";
@import "../../node_modules/datatables.net-responsive-bs/css/responsive.bootstrap.min.css";