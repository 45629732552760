.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
    border-top: 2px solid #e9ecef;
}

.table .table {
    background-color: #fff;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #e9ecef;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e9ecef;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #b8daff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: #dddfe2;
}

.table-hover .table-secondary:hover {
    background-color: #cfd2d6;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #c3e6cb;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #bee5eb;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #ffeeba;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: #fdfdfe;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.table-dark {
    color: #fff;
    background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #32383e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm.table-bordered {
        border: 0;
    }
}

@media (max-width: 767px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md.table-bordered {
        border: 0;
    }
}

@media (max-width: 991px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
    border: 0;
}

table.dataTable.no-footer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
    margin-bottom: 20px;
}

.sorting_asc:focus {
    outline: none;
}

.dataTables_wrapper {
    overflow: hidden;
    padding-bottom: 5px;
}

.dataTables_wrapper .dataTables_length {
    color: #313435;
    float: left;
}

@media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_length {
        text-align: left;
    }
}

.dataTables_wrapper .dataTables_length select {
    border: 1px solid rgba(0, 0, 0, 0.0625);
    border-radius: 2px;
    box-shadow: none;
    height: 35px;
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: #72777a;
    transition: all 0.2s ease-in;
}

.dataTables_wrapper .dataTables_filter {
    color: #313435;
    float: right;
}

@media screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_filter {
        text-align: left;
    }
}

.dataTables_wrapper .dataTables_filter input {
    border: 1px solid rgba(0, 0, 0, 0.0625);
    border-radius: 2px;
    box-shadow: none;
    height: 35px;
    font-size: 14px;
    margin-left: 15px;
    padding: 5px;
    color: #72777a;
    transition: all 0.2s ease-in;
}

.dataTables_wrapper .dataTables_info {
    color: #72777a;
    float: left;
}

.dataTables_wrapper .dataTables_processing {
    color: #313435;
}

.dataTables_wrapper .dataTables_paginate {
    color: #72777a;
    float: right;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #72777a !important;
    padding: 6px 12px;
    border-radius: 2px;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.first,
.dataTables_wrapper .dataTables_paginate .paginate_button.last {
    border-radius: 2px;
    text-decoration: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.first:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.first:focus,
.dataTables_wrapper .dataTables_paginate .paginate_button.last:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.last:focus {
    color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.first.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.last.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: #fff !important;
    background: #7774e7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #fff !important;
    background: #7774e7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    background: #7774e7;
}

.dataTables_wrapper .status {
    width: 5px;
    height: 5px;
}

/*td.control {
    background: url('https://cdn.rawgit.com/DataTables/DataTables/6c7ada53ebc228ea9bc28b1b216e793b1825d188/examples/resources/details_open.png') no-repeat;
    cursor: pointer;
}
tr.shown td.control {
    background: url('https://cdn.rawgit.com/DataTables/DataTables/6c7ada53ebc228ea9bc28b1b216e793b1825d188/examples/resources/details_close.png') no-repeat;
}*/