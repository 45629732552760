.header-container {
    background-color: #fab836;
}

.sidebar-logo {
    background-color: #fab836;
}

.login-right {
    background-color: #fab836;
}

.logo > img {
    width:400%;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background: #fab836 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: #fab836 !important;
}